import * as React from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

function About() {
  const skills = ['ReactJS', 'NodeJS', 'AWS', 'Serverless Architecture'];

  return (
    <Grid id='about' item xs={12} md={6} sx={{ my: 4 }}>
      <CardActionArea component="a" href="#">
        <Card sx={{ display: 'flex' }}>
          <CardContent sx={{ flex: 1 }}>
            <Typography component="h2" variant="h5">
              About Me
            </Typography>
            <Typography variant="body1" color="text.secondary">
              Hi I am Richard Matic, A Senior Software Engineer in the Philippines.
              I have more than 5 years of experience in Software engineering, In my early career
              I am a fullstack developer but currently I am a backend software engieer specializing
              in creating a fault taulerant and scalable API.
            </Typography>
            <List sx={{ listStyle: 'unset' }}>
              {skills.map((skill) => (
                <ListItem key={skill} sx={{ display: 'list-item', p: 0, ml: '2rem' }}>
                  <ListItemText primary={skill} />
                </ListItem>
              ))}
            </List>
          </CardContent>
          <CardMedia
            component="img"
            sx={{ width: '50%', height: '300px', display: { xs: 'none', sm: 'block' } }}
            image='https://source.unsplash.com/random'
            alt='test'
          />
        </Card>
      </CardActionArea>
    </Grid>
  );
}

export default About;