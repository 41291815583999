import * as React from 'react';
import PropTypes from 'prop-types';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import sections from '../components/sections';
import Header from '../components/partials/Header';
import Footer from '../components/partials/Footer';


const theme = createTheme({
  palette: {
    mode: 'light',
  },
});

function Page(props) {
  const { title } = props;

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Container maxWidth="lg">
        <Header title={title} sections={sections} />
        <main>
          {props.children}
        </main>
      </Container>
      <Footer
        title="Footer"
        description="Something here to give the footer a purpose!"
      />
    </ThemeProvider>
  );
}

Page.propTypes = {
  title: PropTypes.string.isRequired,
};

export default Page;