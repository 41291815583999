import * as React from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

function About() {
  return (
    <Grid id='contact' item xs={12} md={6} sx={{ m: '0 auto' }} xl={6}>
      <Typography component="h2" variant="h5">
        Contact Us
      </Typography>
      <Box
        component="form"
        sx={{ '& .MuiTextField-root': { my: 1 } }}
        noValidate
        autoComplete="off"
      >
        <div>
          <TextField
            id="outlined-multiline-flexible"
            label="Name"
            multiline
            sx={{ width: '25ch', mr: 1 }}
            xs={12}
          />
          <TextField
            id="outlined-multiline-flexible"
            label="Email"
            multiline
            sx={{ width: '25ch', ml: 1 }}
            xs={12}
          />
        </div>
        <div>
          <TextField
            id="outlined-multiline-static"
            label="Message"
            multiline
            rows={4}
            sx={{ mx: '0' }}
            fullWidth
            xs={12}
          />
        </div>
      </Box>
    </Grid>
  );
}

export default About;